<template>
  <div class="content-wrapper">
    <div class="expense-statistics main-content">

      <div class="page-header">
        <h1>Payment Statistics</h1>
      </div>

      <div class="chart-wrapper">
        <BarChart :options="chartOptions" />
      </div>

      <div class="payment-statistics-table" v-if="tableData">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Payment Type</th>
              <th scope="col">Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Income</td>
              <td>{{ tableData.Income }}</td>
            </tr>
            <tr>
              <td>Outcome</td>
              <td>{{ tableData.Outcome }}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BarChart from '@/components/chart/BarChart.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'PaymentStatistics',
  components: {
    BarChart,
  },
  data(){
    return {
      tableData: null,
      paymentStatistics: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },
  mounted(){
    this.loadAllPayments();
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async loadAllPayments(){
      const loginInfo = Common.getLoginInfo();
      try{
        const payments = await Financial.loadAllPayment(this.apiUrl, loginInfo);
        let incomeCount = 0;
        let outcomeCount = 0;
        for(let i = 0; i < payments.length; i++){
          const payment = payments[i];
          const paymentType = payment.type;
          if(paymentType === 'Income'){
            incomeCount += 1;
          }else{
            outcomeCount += 1;
          }
        }

        const tableData = {
          'Income': incomeCount,
          'Outcome': outcomeCount,
        };

        this.tableData = tableData;

        const chartData = {
          labels: ['Income', 'Outcome'],
          datasets: [
            {
              label: 'Income and Outcome Statistics',
              backgroundColor: '#343A40',
              data: [incomeCount, outcomeCount],
            }
          ],
        };
        this.setCurrentChartData(chartData);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentChartData']),
  },
  computed: mapState({
   currentChartData: state => state.chart.currentChartData,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.payment-statistics-table{
  margin-top: 50px;
}
</style>
